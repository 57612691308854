/* App.css */

.chat-app {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.app-container {
  text-align: center;
}

.title {
  color: #4f4d4d;
  font-size: 2em; 
}

.message-list {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse; /* Reverse the order to show newer messages at the bottom */
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  max-width: 80%;
}

.user {
  background-color: #009688; /* Teal background for user messages */
  color: #ffffff; /* White text color */
  align-self: flex-end;
}

.bot {
  background-color: #009688; /* Teal background for bot messages */
  color: #ffffff; /* White text color */
  align-self: flex-start;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.input-container input[type="text"] {
  flex: 1;
  padding: 18px;
  margin-right: 10px;
  border: 2px solid #009688; /* Teal border for input field */
  border-radius: 30px;
  font-size: 16px;
  outline: none; /* Remove default focus outline */
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Override default browser styles for focused input elements */
.input-container input[type="text"]:focus {
  border-color: #0a574f; /* Dark teal color when input is focused */
}

.input-container button {
  padding: 10px 20px;
  background-color: #009688; /* Teal background for send button */
  color: #ffffff; /* White text color */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
}

.input-container button:hover {
  background-color: #00796B; /* Darker teal color on hover */
}

.answer-image, .question-image {
  height: 20px;
  width: 20px; 
  /* height: auto;  */
  margin-right: 10px;
}

.answer-container, .question-container {
  display: flex;
  flex-direction: row;
}

.answer-name-text, .question-name-text {
  font-size: 14px;
  color: gray;
}

.message-container {
  margin-top: 60px;
}